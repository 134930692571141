import { ERRORS } from "@amzn/asx-ui-telemetry-client";

/**
 * * Operations - Async operations. Each Operation has an associated metric for each measurement: Time, Success, Fault, Count
 */
const OPERATIONS = {
  GetAccountInfo: "GetAccountInfo",
  GetStaticData: "GetStaticData",
  GetTemplates: "API.getTemplates",
  GetThemeById: "API.getThemeById",
  GetThemes: "API.getThemes",
  CreateTheme: "API.createTheme",
  UpdateTheme: "API.updateTheme",
  BulkUpdateTheme: "API.bulkUpdateTheme",
  DeleteTheme: "API.deleteTheme",
  CreateFeedbackThemeMapping: "API.createFeedbackThemeMapping",
  GetAllFeedbackThemeMappings: "API.getAllFeedbackThemeMappings",
  GetFeedbackThemeMapping: "API.getFeedbackThemeMapping",
  GetCases: "API.getCases",
  CreateOverrideMappingCase: "API.createOverrideMappingCase",
  UpdateOverrideMappingCase: "API.updateOverrideMappingCase",
  GetFeedbackByThemeId: "API.getFeedbackByThemeId",
  GetFeedbacks: "API.getFeedbacks",
  UpdateFeedback: "API.updateFeedback",
  GetFeedback: "API.getFeedback",
  BulkUpdateFeedback: "API.bulkUpdateFeedback",
  GetPurgeStatus: "API.getPurgeStatus",
  DeletePurgeRecord: "API.deletePurgeRecord",
  GetFeatureFlags: "API.getFeatureFlags",
};

const TIMERS = {
  PageLoadToAppLoad: "PageLoadToAppLoad",
  AppLoadToAppReady: "AppLoadToAppReady",
  AllFeedbackPageInitialLoad: "AllFeedbackPageInitialLoad",
  AllFeedbackPageFullLoad: "AllFeedbackPageFullLoad",
  MyFeedbackPageInitialLoad: "MyFeedbackPageInitialLoad",
  MyFeedbackPageFullLoad: "MyFeedbackPageFullLoad",
};

export const OPS_METRICS = {
  TIMERS,
  OPERATIONS,
  ERRORS,
} as const;

export { OPS_METRICS_TYPES } from "@amzn/asx-ui-telemetry-client";
