import VectorWidget from "./pages/widget/VectorWidget";
import FeedbackDetailPage from "./pages/feedback-detail-page/FeedbackDetailPage";
import FeedbackSucessPage from "./pages/sucess-pages/FeedbackSuccessPage";
import SubmitPage from "./pages/feedback-submit-page/SubmitPageModal";
import AllfeedbackTable from "./pages/list-page/AllfeedbackTable";
import FeedbackTrends from "./pages/feedback-trends/FeedbackTrends";
import DownloadAttachments from "./pages/attachment-download-page/attachmentDownloadPage";
import Admin from "./pages/admin-page/Admin";
import AdminFeedbackLabels from "./pages/admin-page/admin-feedback-labels/AdminFeedbackLabels";
import AdminBulkUpload from "./pages/admin-page/admin-bulk-upload/AdminBulkUpload";
import AdminFeedbackcategorization from "./pages/admin-page/admin-feedback-categorization/AdminFeedbackcategorization";
import AdminModalWrapper from "./pages/admin-page/shared-components/AdminModalWrapper";
import AdminFeedbackTemplates from "./pages/admin-page/admin-feedback-templates/AdminFeedbackTemplates";
import FeedbackThemes from "./pages/feedback-themes/FeedbackThemes";
import FeedbackThemeModalWrapper from "./pages/feedback-themes/FeedbackThemeModalWrapper";
import FeedbackThemeDetails from "./pages/feedback-themes/FeedbackThemeDetails";
import ReviewTheme from "./pages/admin-page/review-theme/ReviewTheme";
import { useStore } from "./store";

const AllFeedbackPageWrapper = props => {
  const storeProp = useStore();
  return <AllfeedbackTable {...props} storeProp={storeProp} myFeedbackPage={false} />;
};
const MyFeedbackPageWrapper = props => {
  const storeProp = useStore();
  return <AllfeedbackTable {...props} storeProp={storeProp} myFeedbackPage={true} />;
};
const EditFeedbackPageWrapper = props => <SubmitPage {...props} header="Edit Customer Feedback" mode="edit" />;
const CloneFeedbackPageWrapper = props => <SubmitPage {...props} header="Add Customer Feedback" mode="clone" />;
const TemplateCreateFeedbackPageWrapper = props => (
  <SubmitPage {...props} header="Add Customer Feedback" mode="template" />
);
const CreateFeedbackCategorizationModalWrapper = props => (
  <AdminModalWrapper {...props} adminEntity="categorization" mode="Create" />
);
const CreateLabelsModalWrapper = props => <AdminModalWrapper {...props} adminEntity="labels" mode="Create" />;
const AdminFeedbackcategorizationWrapper = props => <AdminFeedbackcategorization {...props} entity="categorization" />;
const EditFeedbackCategorizationModalWrapper = props => (
  <AdminModalWrapper {...props} adminEntity="categorization" mode="Edit" />
);
const CreateTemplateFormWrapper = props => <AdminModalWrapper {...props} adminEntity="template" mode="Create" />;
const DeleteFeedbackCategorizationModalWrapper = props => (
  <AdminModalWrapper {...props} adminEntity="categorization" mode="Delete" />
);
const FeedbackThemesWrapper = props => <FeedbackThemes {...props} />;
const CreateFeedbackThemeWrapper = props => <FeedbackThemeModalWrapper mode="Create" />;
const FeedbackTrendsWrapper = props => {
  const { updateFeedbackList, feedbacksList, lastUpdatedTimeAllFeedback, setlastUpdatedTimeAllFeedback } = useStore();
  const storeProp = {
    updateFeedbackList,
    lastUpdatedTimeAllFeedback,
    feedbacksList,
    setlastUpdatedTimeAllFeedback,
  };
  return <FeedbackTrends {...props} storeProp={storeProp} />;
};
// const CreateFeedbackTemplateModalWrapper = (props) => (
//     <CreateFeedbackTemplateModal {...props} mode = "Create" />
// )
const FeedbackDetailPageWrapper = props => {
  const storeProp = useStore();
  return <FeedbackDetailPage {...props} storeProp={storeProp} />;
};

export const routeMapping = [
  {
    path: "/",
    component: AllFeedbackPageWrapper,
  },
  {
    path: "/feature",
    component: AllFeedbackPageWrapper,
  },
  {
    path: "/feedback",
    component: MyFeedbackPageWrapper,
  },
  {
    path: "/trends",
    component: FeedbackTrendsWrapper,
  },
  {
    path: "/editfeedback/:id",
    component: EditFeedbackPageWrapper,
  },
  {
    path: "/template/:id",
    component: TemplateCreateFeedbackPageWrapper,
  },
  {
    path: "/clonefeedback/:id",
    component: CloneFeedbackPageWrapper,
  },
  {
    path: "/feedbacksuccess",
    component: FeedbackSucessPage,
  },
  {
    path: "/list/feedback/my",
    component: MyFeedbackPageWrapper,
  },
  {
    path: "/list/feature/recent",
    component: AllFeedbackPageWrapper,
  },
  {
    path: "/vector/acfwidget/",
    component: VectorWidget,
  },
  {
    path: "/recentfeature",
    component: AllFeedbackPageWrapper,
  },
  {
    path: "/list/:object/:filter",
    component: AllFeedbackPageWrapper,
  },
  {
    path: "/feedback/:id",
    component: FeedbackDetailPageWrapper,
  },
  {
    path: "/feature/:id",
    component: FeedbackDetailPageWrapper,
  },
  {
    path: "/download/:id/:filename",
    component: DownloadAttachments,
  },
  {
    path: "/admin",
    component: Admin,
    isAdminOrReviewer: true,
  },
  {
    path: "/admin/bulk-upload",
    component: AdminBulkUpload,
    isAdminRoute: true,
  },
  {
    path: "/admin/labels",
    component: AdminFeedbackLabels,
    isAdminRoute: true,
  },
  {
    path: "/admin/labels/create",
    component: CreateLabelsModalWrapper,
    isAdminRoute: true,
  },
  {
    path: "/admin/categorization",
    component: AdminFeedbackcategorizationWrapper,
    isAdminRoute: true,
  },
  {
    path: "/admin/categorization/create",
    component: CreateFeedbackCategorizationModalWrapper,
    isAdminRoute: true,
  },
  {
    path: "/admin/categorization/delete",
    component: DeleteFeedbackCategorizationModalWrapper,
    isAdminRoute: true,
  },
  {
    path: "/admin/categorization/update",
    component: EditFeedbackCategorizationModalWrapper,
    isAdminRoute: true,
  },
  {
    path: "/admin/template",
    component: AdminFeedbackTemplates,
    isAdminRoute: true,
  },
  {
    path: "/admin/template/create",
    component: CreateTemplateFormWrapper,
    isAdminRoute: true,
  },
  {
    path: "/themes",
    component: FeedbackThemesWrapper,
  },
  {
    path: "/themes/create",
    component: CreateFeedbackThemeWrapper,
    isThemeAdmin: true,
  },
  {
    path: "/themes/:id",
    component: FeedbackThemeDetails,
  },
  {
    path: "/admin/review-theme",
    component: ReviewTheme,
    isAdminRoute: true,
    isReviewerRoute: true,
  },
];
