import React, { useEffect, MouseEvent } from "react";
import { Snackbar, ProgressIndicator, Card, Flex } from "@amzn/storm-ui";

interface ExportProgressSnackbarProps {
  progress: number;
  onClose: () => void;
}

const ExportProgressSnackbar: React.FC<ExportProgressSnackbarProps> = ({ progress, onClose }) => {
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (progress === 100) {
      timer = setTimeout(() => {
        onClose();
      }, 2000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [progress, onClose]);

  const handleClose = (_event: MouseEvent<HTMLButtonElement>, reason?: string) => {
    if (reason === "timeout" || reason === "clickaway") {
      onClose();
    }
  };

  return (
    <Snackbar id="export-progress-snackbar" onClose={handleClose}>
      <Card>
        <Flex direction="column">
          <div>{progress === 100 ? "Export complete!" : `Exporting... ${Math.round(progress)}%`}</div>
          <ProgressIndicator value={progress} />
        </Flex>
      </Card>
    </Snackbar>
  );
};

export default ExportProgressSnackbar;
