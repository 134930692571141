import { withInstrumentation } from "@amzn/asx-ui-telemetry-client";
import Logger from "./logger";
import apiClient from "./apiClient";
import { PREV_2_YEARS } from "./constants";
import { createEMFObject } from "./util";
import { telemetryClient } from "./telemetry/client";
import { OPS_METRICS } from "./telemetry/constants";

// Theme APIs

export const getThemebyId = withInstrumentation(telemetryClient, OPS_METRICS.OPERATIONS.GetThemeById, async id => {
  const theme = await apiCaller(`/themes/${id}`, "GET", {});
  return theme;
});

export const getThemes = withInstrumentation(telemetryClient, OPS_METRICS.OPERATIONS.GetThemes, async (from, size) => {
  const themes = await apiCaller("/themes", "GET", {
    from,
    size,
  });
  return themes;
});

export const createTheme = withInstrumentation(telemetryClient, OPS_METRICS.OPERATIONS.CreateTheme, async submitObj => {
  const theme = await apiCaller("/theme", "POST", submitObj);
  return theme;
});

export const updateTheme = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.UpdateTheme,
  async (submitObj, id, queryParam = "") => {
    const theme = await apiCaller(`/theme/${id}${queryParam}`, "PUT", submitObj);
    return theme;
  }
);

export const bulkUpdateTheme = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.BulkUpdateTheme,
  async submitObj => {
    const feedback = await apiCaller("/updatethemes", "PUT", submitObj);
    return feedback;
  }
);

export const deleteTheme = withInstrumentation(telemetryClient, OPS_METRICS.OPERATIONS.DeleteTheme, async id => {
  const theme = await apiCaller(`/theme/${id}`, "DELETE");
  return theme;
});

// Theme Feedback Mapping APIs
export const createFeedbackThemeMapping = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.CreateFeedbackThemeMapping,
  async submitObj => {
    const feedbackThemeMapping = await apiCaller("/thememapping", "POST", submitObj);
    return feedbackThemeMapping;
  }
);

export const getAllFeedbackThemeMappings = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.GetAllFeedbackThemeMappings,
  async () => {
    const themeMappings = await apiCaller("/thememappings", "GET", {
      from: 0,
      size: 50,
      sortby: "payload.createdTimestamp",
      filters: `days:${PREV_2_YEARS}`,
    });
    return themeMappings;
  }
);

export const getFeedbackThemeMapping = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.GetFeedbackThemeMapping,
  async id => {
    const fbMapping = await apiCaller(`/thememappings/${id}`, "GET", {
      from: 0,
      size: 50,
      sortby: "payload.createdTimestamp",
    });
    return fbMapping;
  }
);

// Review mapping APIs
export const getCases = withInstrumentation(telemetryClient, OPS_METRICS.OPERATIONS.GetCases, async (from, size) => {
  const cases = await apiCaller(`/mappingapprovals`, "GET", {
    from,
    size,
    sortby: "payload.createdTimestamp",
    filters: `reviewStatus:pending`,
  });
  return cases;
});

export const createOverrideMappingCase = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.CreateOverrideMappingCase,
  async submitObj => {
    const overrideMappingCase = await apiCaller("/mappingapproval", "POST", submitObj);
    return overrideMappingCase;
  }
);

export const updateOverrideMappingCase = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.UpdateOverrideMappingCase,
  async submitObj => {
    const overrideMappingCase = await apiCaller("/mappingapproval", "PUT", submitObj);
    return overrideMappingCase;
  }
);

// Feedback APIs
export const getFeedbackByThemeId = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.GetFeedbackByThemeId,
  async (id, filter = "") => {
    const feedbacks = await apiCaller(`/feedbacksByThemeId/${id}`, "GET", {});
    return feedbacks;
  }
);

export const getFeedbacks = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.GetFeedbacks,
  async (filters, from, size) => {
    const feedbacks = await apiCaller("/feedbacks", "GET", {
      from,
      size,
      sortby: "payload.createdTimestamp+payload.id",
      filters,
    });
    return feedbacks;
  }
);

export const updateFeedback = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.UpdateFeedback,
  async (submitObj, id) => {
    const feedback = await apiCaller(`/feedback/${id}`, "PUT", submitObj);
    return feedback;
  }
);

export const getFeedback = withInstrumentation(telemetryClient, OPS_METRICS.OPERATIONS.GetFeedback, async id => {
  const feedback = await apiCaller(`feedbacks/${id}`, "GET");
  return feedback;
});

export const bulkUpdateFeedback = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.BulkUpdateFeedback,
  async submitObj => {
    const feedback = await apiCaller("/updatefeedbacks", "PUT", submitObj);
    return feedback;
  }
);

// Purge APIs
export const getPurgeStatus = withInstrumentation(telemetryClient, OPS_METRICS.OPERATIONS.GetPurgeStatus, async id => {
  const purgeStatus = await apiCaller(`/purge/${id}`, "GET");
  return purgeStatus;
});

export const deletePurgeRecord = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.DeletePurgeRecord,
  async id => {
    const purgeStatus = await apiCaller(`/purge/${id}`, "DELETE");
    return purgeStatus;
  }
);

// Feature flags API
export const getFeatureFlags = withInstrumentation(
  telemetryClient,
  OPS_METRICS.OPERATIONS.GetFeatureFlags,
  async () => {
    const flags = await apiCaller("/featureflag", "GET");
    return flags;
  }
);

//API caller function
const apiCaller = (url, method, params = {}) => {
  switch (method) {
    case "GET":
      try {
        return apiClient.get(url, { params });
      } catch (err) {
        Logger.sessionError(`${url} ${params} Failed`, {
          emfLog: createEMFObject(`${method}${url}Failed`, 1, "Count", {
            GETapiFailed: url + "Failed",
          }),
        });
      }
      break;
    case "PUT":
      try {
        return apiClient.put(url, params);
      } catch (err) {
        Logger.sessionError(`${url} Failed`, {
          emfLog: createEMFObject(`${method}${url}Failed`, 1, "Count", {
            PUTapiFailed: url + "Failed",
          }),
        });
      }
      break;
    case "POST":
      try {
        return apiClient.post(url, params);
      } catch (err) {
        Logger.sessionError(`${url} Failed`, {
          emfLog: createEMFObject(`${method}${url}Failed`, 1, "Count", {
            POSTapiFailed: url + "Failed",
          }),
        });
      }
      break;
    default:
      try {
        return apiClient.delete(url, params);
      } catch (err) {
        Logger.sessionError(`${url} Failed`, {
          emfLog: createEMFObject(`${method}${url}Failed`, 1, "Count", {
            DELETEapiFailed: url + "Failed",
          }),
        });
      }
      break;
  }
};
