import { FilterProcessor } from "../types/FilterProcessor";
import { FilterCriteria } from "../types/FilterCriteria";

export abstract class BaseProcessor implements FilterProcessor {
  abstract canProcess(filterType: string): boolean;
  abstract process(field: string, criteria: FilterCriteria): string;

  protected createFilterString(field: string, value: string): string {
    return `+${field}:${value}`;
  }
}
