import { UnifiedDataTable } from "@amzn/unified-data-table-components/pack";
import React from "react";

export class ClientSideTable extends React.Component {
  static getDefaultGridOptions() {
    return {
      suppressMenu: true,
      defaultColDef: {
        headerClass: "tableHeader",
        cellClass: "tableCell",
      },
    };
  }

  render() {
    const {
      uidPrefix,
      tableFooter,
      gridOptions,
      onGridReady,
      headerPanel,
      onFilterChanged,
      onColumnPresetChanged,
      frameworkComponents,
    } = this.props;

    return (
      <UnifiedDataTable
        frameworkComponents={frameworkComponents || {}}
        gridOptions={gridOptions}
        uidPrefix={uidPrefix}
        onGridReady={onGridReady}
        footerPanel={tableFooter}
        headerPanel={headerPanel}
        onFilterChanged={onFilterChanged}
        onColumnPresetChanged={onColumnPresetChanged}
        maxTableHeight="67vh"
        locale="en-US"
      />
    );
  }
}
