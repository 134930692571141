import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router";
import {
  Text,
  BreadcrumbGroup,
  Breadcrumb,
  TextButton,
  Dropdown,
  DropdownItem,
  Link,
  Button,
  Icon,
  Alert,
  Modal,
  TextAreaFormGroup,
} from "@amzn/storm-ui";
import { Form, FormRow } from "@amzn/storm-ui-column-form-kit";
import { getThemebyId, getFeedbacks } from "../../util/apiUtils";
import ObjectRow from "../../components/object-row/ObjectRow";
import Column from "../../components/column";
import { useParams } from "react-router-dom";
import AssociatedFeedbacks from "./AssociatedFeedbacks";
import Row from "../../components/row";
import { THEME_STATUS, THEME_PRIORITY, IS_THEME_ADMIN } from "../../util/constants";
import { updateTheme } from "../../util/apiUtils";
import Logger, { logPageMetric } from "../../util/logger";
import Loader from "../../components/Loader";
import { getPhoneToolLink } from "../../util/helperFunctions";
import { createEMFObject, formatDateTime, getEnvUrl } from "../../util/util";
import FeedbackThemeModalWrapper from "./FeedbackThemeModalWrapper";
import { TABLE_ROWS_COUNT } from "../../util/constants";
import { Page } from "../../typings/enum";
import { copy } from "@amzn/storm-ui-icons";
import { useStore } from "../../store";
import DatePicker from "@amzn/storm-ui-date-picker";

const FeedbackThemeDetails = props => {
  const history = useHistory();
  const [themeInfo, setThemeInfo] = useState();
  const [childrenFeedbacks, setChildrenFeedbacks] = useState([]);
  const [childFeedbackLoading, setChildFeedbackLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statusDetails, setStatusDetails] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [selectedEta, setSelectedEta] = useState("");
  const [openEditModel, setOpenEditModel] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingPriority, setUpdatingPriority] = useState(false);
  const [updatingEta, setUpdatingEta] = useState(false);
  const [themeEdited, setThemeEdited] = useState(false);
  const [statusPriorityUpdated, setStatusPriorityUpdated] = useState(false);
  const [statusEtaUpdated, setStatusEtaUpdated] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [showWaitText, setShowWaitText] = useState(false);
  const [isThemeDeleted, setIsThemeDeleted] = useState(false);
  const [isThemeInvalid, setIsThemeInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isThemeLaunchDateModalOpen, setIsThemeLaunchDateModalOpen] = useState(false);
  const toggleModal = useCallback(() => {
    setIsModalOpen(prev => !prev);
  }, []);
  const buttonRef = useRef(null);

  const Footer = ({ cancelThemeStatus, updateThemeStatus }) => (
    <>
      <TextButton onClick={cancelThemeStatus}>Cancel</TextButton>
      <Button onClick={updateThemeStatus} primary>
        {updatingStatus ? "Saving..." : "Save"}
      </Button>
    </>
  );

  const ThemeLaunchDateFooter = ({
    selectedEta,
    currentEta,
    cancelThemeLaunchDate,
    updateThemeLaunchDate,
    clearThemeLaunchDate,
  }) => (
    <Row>
      <Column>
        <TextButton onClick={cancelThemeLaunchDate}>Cancel</TextButton>
      </Column>
      <Column>
        {currentEta !== 0 && <Button onClick={clearThemeLaunchDate}>{updatingEta ? "Clearing..." : "Clear"}</Button>}
      </Column>
      <Column>
        <Button onClick={updateThemeLaunchDate} disabled={currentEta === selectedEta || selectedEta === 0} primary>
          {updatingEta ? "Saving..." : "Save"}
        </Button>
      </Column>
    </Row>
  );

  const { userPermissions } = useStore();

  useEffect(() => {
    async function fetchData() {
      if (id) {
        //fetch theme details
        const themeDetails = await getThemebyId(id);
        setThemeInfo(themeDetails?.data?.theme?.fields);
        setSelectedStatus(themeInfo?.themeStatus);
        if (!handleThemeValidity(themeDetails)) {
          return;
        }
        const shouldDisableEdit = themeDetails?.data?.theme?.fields?.reviewStatus === "pending" || false;
        setDisableEdit(shouldDisableEdit);
        setShowBanner(shouldDisableEdit);
        if (shouldDisableEdit) {
          setShowWaitText(false);
        }

        // fetch child feedback details
        const childfeedbacks = await getFeedbackFilterOnThemeId(id);
        setChildrenFeedbacks(childfeedbacks?.data?.feedbackIdList || []);
        setChildFeedbackLoading(false);
        setIsLoading(false);
      }
      logPageMetric(Page.ThemeDetailPage);
    }
    displayBanner();
    fetchData();
    Logger.sessionInfo("Theme details Visited", {
      emfLog: createEMFObject("PageVisits", 1, "Count", {
        PageVisits: "FeedbackThemeDetailsVisits",
      }),
    });
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(async () => {
    if ((themeEdited && id) || (statusPriorityUpdated && id) || (statusEtaUpdated && id)) {
      setUpdatingStatus(true);
      const themeDetails = await getThemebyId(id);
      const shouldDisableEdit = themeDetails?.data?.theme?.fields?.reviewStatus === "pending" || false;
      setThemeInfo(themeDetails?.data?.theme?.fields);
      setDisableEdit(shouldDisableEdit);
      setShowBanner(shouldDisableEdit);
      if (shouldDisableEdit) {
        setShowWaitText(false);
      }

      setUpdatingStatus(false);
    }
    setThemeEdited(false);
    setStatusPriorityUpdated(false);
    setStatusEtaUpdated(false);
    displayBanner();
    // eslint-disable-next-line
  }, [themeEdited, statusPriorityUpdated, statusEtaUpdated]);

  const displayBanner = () => {
    if (sessionStorage != null) {
      const isMappingupdated = sessionStorage.getItem("isMappingupdated");
      const isMappingApprovalCaseCreated = sessionStorage.getItem("isMappingApprovalCaseCreated");
      if (isMappingApprovalCaseCreated === "true") {
        setDisableEdit(true);
        setShowBanner(true);
      } else if (isMappingupdated) {
        setShowWaitText(true);
      }
      sessionStorage.removeItem("isMappingupdated");
      sessionStorage.removeItem("isMappingApprovalCaseCreated");
    }
  };

  const handleThemeValidity = themeDetails => {
    if (!themeDetails?.data?.theme) {
      setIsThemeInvalid(true);
    } else if (themeDetails.data.theme.fields?.isDeleted === "1") {
      setIsThemeDeleted(true);
    } else {
      return true;
    }
    setIsLoading(false);
    return false;
  };

  const getFeedbackFilterOnThemeId = async id => {
    id = decodeURIComponent(id);
    getFeedbacks(`parentTheme:${id}`, 0, TABLE_ROWS_COUNT)
      .then(response => {
        setChildrenFeedbacks(response.data.results);
        let requestsMade = TABLE_ROWS_COUNT;
        let bucketSize = TABLE_ROWS_COUNT;
        let index = 1;
        const ceilingBucketSize = 2000;
        while (requestsMade < response.data.totalItemsCount) {
          index++;
          if (bucketSize < ceilingBucketSize) {
            bucketSize = TABLE_ROWS_COUNT * index;
          }
          getFeedbacks(`parentTheme:${id}`, requestsMade, bucketSize)
            .then(response => {
              const {
                data: { results: moreFeedbacks },
              } = response;
              setChildrenFeedbacks(childrenFeedbacks => childrenFeedbacks.concat(...moreFeedbacks));
            })
            .catch(error => {
              Logger.sessionError(
                `FeedbackThemeDetailPage getFeedback api paginated call failure from
                     ${error}`,
                {
                  browserUrl: window.location.href,
                }
              );
            });
          requestsMade += bucketSize;
        }
      })
      .catch(error => {
        Logger.sessionError(
          `FeedbackThemeDetailPage FeedbackTable useEffect,
                 ${error}`,
          {
            browserUrl: window.location.href,
          }
        );
      });
  };

  const cancelThemeStatus = async () => {
    setIsModalOpen(false);
    setSelectedStatus(themeInfo?.themeStatus);
    setStatusDetails("");
  };

  const updateThemeStatus = async () => {
    // const { id } = themeInfo

    if (themeInfo?.themeStatus !== selectedStatus) {
      setUpdatingStatus(true);
      const updateThemeObj = {
        themeId: id,
        input: {
          fields: {
            ...themeInfo,
            themeStatus: selectedStatus,
            statusDetails: statusDetails,
          },
        },
      };
      await updateTheme(updateThemeObj, id, "?updatestatus=true");
      Logger.sessionInfo(
        `updated themeStatus successfully from ${themeInfo.themeStatus} to ${selectedStatus} for theme ${id}`
      );
      setUpdatingStatus(false);
      setStatusPriorityUpdated(true);
    }
    setIsModalOpen(false);
  };

  const createModalOpen = () => {
    setOpenEditModel(true);
  };
  const copyUrlCTAHandler = () => {
    const url = getEnvUrl(encodeURIComponent(id), "themes");
    window.navigator.clipboard.writeText(url);
    Logger.sessionInfo(`Copy url cta clicked`, {
      emfLog: createEMFObject(`CopyUrlThemeClick`, 1, "Count"),
    });
  };

  const updateThemePriority = async val => {
    // const { id } = themeInfo
    setSelectedPriority(val);
    setUpdatingPriority(true);
    const updateThemeObj = {
      themeId: id,
      input: {
        fields: {
          ...themeInfo,
          themePriority: val,
        },
      },
    };
    await updateTheme(updateThemeObj, id);
    Logger.sessionInfo(`updated themePriority successfully ${id}`);

    setUpdatingPriority(false);
    setStatusPriorityUpdated(true);
  };

  const handleUpdateThemeLaunchDate = async () => {
    let selectedThemeEta = Math.floor(selectedEta.valueOf() / 1000).toString();
    if (themeInfo?.themeEta !== selectedThemeEta) {
      setSelectedEta(selectedThemeEta);
      setUpdatingEta(true);
      const updateThemeObj = {
        themeId: id,
        input: {
          fields: {
            ...themeInfo,
            themeEta: selectedThemeEta,
          },
        },
      };
      await updateTheme(updateThemeObj, id);
      Logger.sessionInfo(`updated themeEta successfully ${id}`);

      setUpdatingEta(false);
      setStatusEtaUpdated(true);
    }

    setIsThemeLaunchDateModalOpen(false);
  };

  const handleClearThemeLaunchDate = async () => {
    setSelectedEta("");
    setUpdatingEta(true);
    const updateThemeObj = {
      themeId: id,
      input: {
        fields: {
          ...themeInfo,
          themeEta: "",
        },
      },
    };
    await updateTheme(updateThemeObj, id);
    Logger.sessionInfo(`cleared themeEta successfully ${id}`);

    setUpdatingEta(false);
    setStatusEtaUpdated(true);

    setIsThemeLaunchDateModalOpen(false);
  };

  // const refreshChildFeedbacks = async () => {
  //     console.log("refreshing")
  //     setChildFeedbackLoading(true);
  //     const childfeedbacks = await getFeedbackFilterOnThemeId(id);
  //     setChildrenFeedbacks(childfeedbacks?.data?.feedbackIdList || []);
  //     setChildFeedbackLoading(false);
  // }

  const renderStatusDropdown = () => {
    return (
      <Row>
        <Modal
          isOpen={isModalOpen}
          header="Status Change Details"
          footer={<Footer cancelThemeStatus={cancelThemeStatus} updateThemeStatus={updateThemeStatus} />}
          toggleEl={buttonRef.current}
        >
          <Form id="editStatus">
            <FormRow
              label="Status"
              isSectionHeader
              id="status-update"
              controlComponents={[
                componentId => (
                  <Dropdown
                    onChange={val => setSelectedStatus(val)}
                    selectedValue={selectedStatus || themeInfo?.themeStatus || "Update status"}
                  >
                    {THEME_STATUS.map((themeStatus, key) => {
                      return (
                        <DropdownItem key={key} value={themeStatus}>
                          {themeStatus}
                        </DropdownItem>
                      );
                    })}
                  </Dropdown>
                ),
              ]}
            />

            <FormRow
              label="Status Details"
              isSectionHeader
              id="status-details-update"
              controlComponents={[
                componentId => (
                  <TextAreaFormGroup
                    placeholder="Details about the theme status change"
                    value={statusDetails}
                    onChange={event => {
                      setStatusDetails(event.target.value);
                    }}
                  />
                ),
              ]}
            />
          </Form>
        </Modal>

        <Button
          disabled={childrenFeedbacks?.length === 0 || disableEdit || !userPermissions[IS_THEME_ADMIN]}
          id="statusButton"
          onClick={toggleModal}
          ref={buttonRef}
        >
          <Text>{themeInfo?.themeStatus || "Update status"}</Text>
        </Button>
      </Row>
    );
  };

  const renderPriorityDropdown = () => {
    return (
      <Row>
        <Dropdown
          disabled={childrenFeedbacks?.length === 0 || disableEdit || !userPermissions[IS_THEME_ADMIN]}
          onChange={val => updateThemePriority(val)}
          selectedValue={selectedPriority}
          onOverrideLabel={(dropdownItem, value) => {
            return themeInfo?.themePriority || "Priority not set";
          }}
          transparentButton={true}
        >
          {THEME_PRIORITY.map((themePriority, key) => {
            return (
              <DropdownItem key={key} value={themePriority}>
                {themePriority}
              </DropdownItem>
            );
          })}
        </Dropdown>
      </Row>
    );
  };

  const handleCancelThemeLaunchDate = () => {
    setIsThemeLaunchDateModalOpen(false);
    setSelectedEta(themeInfo?.themeEta);
  };

  const toggleThemeLaunchDateModal = () => {
    setIsThemeLaunchDateModalOpen(!isThemeLaunchDateModalOpen);
  };

  const renderEditThemeLaunchDateModal = (themeEtaInSeconds, themeStatus) => {
    return (
      <Modal
        isOpen={isThemeLaunchDateModalOpen}
        header="Update Estimated Theme Launch Date"
        footer={
          <ThemeLaunchDateFooter
            selectedEta={selectedEta}
            currentEta={themeEtaInSeconds * 1000}
            cancelThemeLaunchDate={handleCancelThemeLaunchDate}
            updateThemeLaunchDate={handleUpdateThemeLaunchDate}
            clearThemeLaunchDate={handleClearThemeLaunchDate}
          />
        }
        onClose={handleCancelThemeLaunchDate}
        toggleEl={buttonRef.current}
      >
        <Form id="editLaunchDate">
          <FormRow
            label="Estimated Theme Launch Date"
            isSectionHeader
            id="launch-date-update"
            controlComponents={[
              _ => (
                <DatePicker
                  placeholder="MM/DD/YYYY"
                  format="MM/DD/YYYY"
                  date={themeEtaInSeconds !== 0 ? new Date(themeEtaInSeconds * 1000) : new Date()}
                  onChange={val => setSelectedEta(val)}
                  maxDate={themeStatus === "launched" ? new Date() : undefined}
                  minDate={themeStatus === "launched" ? undefined : new Date()}
                />
              ),
            ]}
          />
        </Form>
      </Modal>
    );
  };

  const renderThemeEta = () => {
    let themeEtaInSeconds = parseInt(themeInfo?.themeEta) || 0;
    let themeStatus = themeInfo?.themeStatus?.toLowerCase() || "";

    return (
      <Row>
        {renderEditThemeLaunchDateModal(themeEtaInSeconds, themeStatus)}

        {themeEtaInSeconds !== 0 && <Text type="span">{formatDateTime(themeEtaInSeconds)}</Text>}

        <Button
          data-testid="estimate-launch-date-button"
          disabled={childrenFeedbacks?.length === 0 || disableEdit || !userPermissions[IS_THEME_ADMIN]}
          onClick={toggleThemeLaunchDateModal}
          ref={buttonRef}
          type="link"
        >
          {themeEtaInSeconds === 0 ? "Add Theme Launch Date" : "Edit"}
        </Button>
      </Row>
    );
  };

  if (isLoading || updatingStatus || updatingPriority || updatingEta) {
    return (
      <div className="page">
        <Loader />
      </div>
    );
  }
  if (isThemeInvalid || isThemeDeleted) {
    return (
      <Column spacingInset="medium">
        <BreadcrumbGroup>
          <Breadcrumb>
            <TextButton onClick={() => history.push("/themes")}>Themes</TextButton>
          </Breadcrumb>
          <Breadcrumb label="Theme details" />
        </BreadcrumbGroup>
        <Alert
          type={isThemeInvalid ? "error" : "warning"}
          header={isThemeInvalid ? "Invalid Theme ID" : "Theme Deleted"}
        >
          <Text>
            {isThemeInvalid
              ? "The theme ID you are looking for is invalid or does not exist."
              : "The theme you are looking for has been deleted."}
          </Text>
        </Alert>
      </Column>
    );
  }
  if (openEditModel) {
    return (
      <FeedbackThemeModalWrapper
        closeEditModal={setOpenEditModel}
        isThemeEdited={setThemeEdited}
        {...props}
        mode="Edit"
        source="themesDetailsPage"
        selectedRowItems={[
          {
            rowData: { ...themeInfo, id },
          },
        ]}
      />
    );
  }

  return (
    <>
      <Column spacingInset="400">
        <BreadcrumbGroup>
          <Breadcrumb>
            <TextButton onClick={() => history.push("/themes")}>Themes</TextButton>
          </Breadcrumb>
          <Breadcrumb label="Theme details" />
        </BreadcrumbGroup>
        <Alert type="info" header="Your changes might take a few minutes to reflect" isOpen={showWaitText} />
        <Alert
          type="warning"
          header="Pending Theme Approval"
          withCloseButton={true}
          isOpen={showBanner && disableEdit}
          onClose={() => setShowBanner(false)}
        >
          <Text>
            Edits made to this theme are under review. You cannot make additional changes until edits are reviewed and
            approved.
          </Text>
        </Alert>
        <Row width="100%" alignmentHorizontal="justify">
          <Text type="h2">Feedback theme</Text>
          <Column alignmentHorizontal="end">
            <Row alignmentHorizontal="justify">
              {userPermissions[IS_THEME_ADMIN] && (
                <Button disabled={disableEdit} primary={true} onClick={() => createModalOpen()}>
                  {" "}
                  Edit{" "}
                </Button>
              )}
              <Button onClick={() => copyUrlCTAHandler()}>
                <Icon type={copy} /> Copy url{" "}
              </Button>
            </Row>
          </Column>
        </Row>

        <ObjectRow
          columnData={[
            {
              dataKey: <Text type="span">Product name</Text>,
              dataValue: <Text type="span">{themeInfo?.productServices}</Text>,
            },
          ]}
        />
        <ObjectRow
          columnData={[
            {
              dataKey: <Text type="span">Theme name</Text>,
              dataValue: <Text type="span">{themeInfo?.themeName}</Text>,
            },
          ]}
        />
        <ObjectRow
          columnData={[
            {
              dataKey: <Text type="span">Description</Text>,
              dataValue: <Text type="span">{themeInfo?.additionalContext}</Text>,
            },
          ]}
        />
        <ObjectRow
          columnData={[
            {
              dataKey: <Text type="span">Blocked revenue</Text>,
              dataValue: <Text type="span">{themeInfo?.blockedRevenue}</Text>,
            },
          ]}
        />
        <ObjectRow
          columnData={[
            {
              dataKey: <Text type="span">Attached feedback count</Text>,
              dataValue: <Text type="span">{themeInfo?.feedbackCount || 0}</Text>,
            },
          ]}
        />
        <ObjectRow
          columnData={[
            {
              dataKey: <Text type="span">Priority</Text>,
              dataValue: renderPriorityDropdown(),
            },
          ]}
        />
        <ObjectRow
          columnData={[
            {
              dataKey: <Text type="span">Status</Text>,
              dataValue: renderStatusDropdown(),
            },
          ]}
        />
        <ObjectRow
          columnData={[
            {
              dataKey: <Text type="span">Estimated Launch Date</Text>,
              dataValue: renderThemeEta(),
            },
          ]}
        />
        <ObjectRow
          columnData={[
            {
              dataKey: <Text type="span">Created by</Text>,
              dataValue: (
                <Link
                  className="object-details__value"
                  href={getPhoneToolLink(themeInfo?.submittedByEmail)}
                  target="_blank"
                >
                  {themeInfo?.submittedByName}
                </Link>
              ),
            },
          ]}
        />
        <ObjectRow
          columnData={[
            {
              dataKey: <Text type="span">Last editted by</Text>,
              dataValue: themeInfo?.lastModifiedByEmail ? (
                <Link
                  className="object-details__value"
                  href={getPhoneToolLink(themeInfo?.lastModifiedByEmail)}
                  target="_blank"
                >
                  {themeInfo?.lastModifiedByName}
                </Link>
              ) : (
                "-"
              ),
            },
          ]}
        />
        {/* <ObjectRow
                    columnData={[
                        {
                            dataKey: <Text type="span">Associated Feedbacks</Text>,
                            dataValue: <AssociatedFeedbacks key={childrenFeedbacks?.length} childFeedbackLoading={childFeedbackLoading} associatedFeedbacks={childrenFeedbacks} />
                        }
                    ]}
                /> */}
        <Text type="h3">list # of feedbacks associated</Text>
        <AssociatedFeedbacks
          key={childrenFeedbacks?.length}
          childFeedbackLoading={childFeedbackLoading}
          associatedFeedbacks={childrenFeedbacks}
        />
      </Column>
    </>
  );
};

export default FeedbackThemeDetails;
