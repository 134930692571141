import { FilterCriteria } from "../types/FilterCriteria";
import { BaseProcessor } from "./BaseProcessor";
import { FILTER_TYPES } from "../constants/FilterConstants";

export class DateProcessor extends BaseProcessor {
  canProcess(filterType: string): boolean {
    return filterType === FILTER_TYPES.DATE;
  }

  process(field: string, criteria: FilterCriteria): string {
    if (!criteria.dateFrom || !criteria.dateTo) {
      throw new Error("dateFrom and dateTo are required");
    }
    const fromTimestamp = Math.floor(new Date(criteria.dateFrom).getTime() / 1000);
    const toTimestamp = Math.floor(new Date(criteria.dateTo).getTime() / 1000);
    return `+${field}:[${fromTimestamp} TO ${toTimestamp}]`;
  }
}
