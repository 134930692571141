import { FilterModel } from "../types/FilterTypes";
import { FilterCriteria } from "../types/FilterCriteria";
import { TextProcessor } from "../processors/TextProcessor";
import { NumberProcessor } from "../processors/NumberProcessor";
import { DateProcessor } from "../processors/DateProcessor";
import { MultiSelectProcessor } from "../processors/MultiSelectProcessor";
import { BaseProcessor } from "../processors/BaseProcessor";

export class FilterService {
  private processors: BaseProcessor[];

  constructor() {
    this.processors = [new TextProcessor(), new NumberProcessor(), new DateProcessor(), new MultiSelectProcessor()];
  }

  processFilters(filterModel: FilterModel): string {
    try {
      if (!filterModel) return "";

      return Object.entries(filterModel)
        .map(([field, criteria]) => this.processSingleFilter(field, criteria))
        .filter(Boolean)
        .join("");
    } catch (error) {
      return "";
    }
  }

  private processSingleFilter(field: string, criteria: FilterCriteria): string {
    if (!criteria?.filterType) return "";

    const processor = this.processors.find(p => p.canProcess(criteria.filterType));
    return processor ? processor.process(field, criteria) : this.createDefaultFilter(field, criteria);
  }

  private createDefaultFilter(field: string, criteria: FilterCriteria): string {
    return `+${field}:${criteria.filter || ""}`;
  }
}
