import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";

import Logger from "./util/logger";
import { createEMFObject, getEnvironment, getTwoyearOldTimeStamp, inIframe, isOlderdate } from "./util/util";
import { Amplify, Auth } from "aws-amplify";
import AuthUserPoolConfig from "./util/config";
import { parseJwt } from "./authentication/authenticator";
import UserContext from "./context/userContext";
import { PerformanceMarker } from "./typings/enum";
import { routeMapping } from "./routeMapping";
import Loader from "./components/Loader";
import Row from "./components/row";
import apiClient from "./util/apiClient";
import { useStore } from "./store";
import { v4 as uuidv4 } from "uuid";
import { INDEXED_DB_STORE, IS_THEME_ADMIN, IS_ADMIN, IS_MAPPING_REVIEWER, IT_TEST_USER } from "./util/constants";
import { getFromIndexedDb, getManyFromIndexedDb, deleteManyfromIndexedDb } from "./persistantStore";
import { deletePurgeRecord, getFeedbacks, getPurgeStatus, getFeatureFlags } from "./util/apiUtils";
import ErrorAlert from "./components/error-alert/ErrorAlert";

const Routes = props => {
  // const [authToken, setAuthToken] = useState("");
  const [isError, setIsError] = useState(false);
  const [src, setSrc] = useState("");
  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const [userRights, setUserRights] = useState({});
  // const authRetryCount = 1;
  let location = useLocation();
  const history = useHistory();
  const [loadingStaticData, setLoadingStaticData] = useState(true);
  const [loadingStore, setLoadingStore] = useState(true);
  const [isPurgeflagVerified, setIsPurgeFlagVerified] = useState(false);
  const [isFeatureFlagVerified, setIsFeatureFlagVerified] = useState(false);
  const [isIdbInitialised, setIsIdbInitialised] = useState(false);

  const {
    updateStaticData,
    setUserInfo,
    updateFeedbackList,
    updateMyFeedbackList,
    setlastUpdatedTimeMyFeedback,
    setlastUpdatedTimeAllFeedback,
    setTwoYearLength,
    setUserPermissions,
    setFeatureFlags,
  } = useStore();

  const authenticate = () => {
    localStorage.setItem("pathName", window.location.href);
    Amplify.configure({
      Auth: AuthUserPoolConfig[getEnvironment()],
    });
    try {
      let shouldSignOut = localStorage.getItem("shouldSignOut");
      console.log(shouldSignOut);
      if (shouldSignOut !== "signedOut") {
        localStorage.setItem("shouldSignOut", "signedOut");
        Logger.sessionInfo("User loggedout to force token update", {
          emfLog: createEMFObject("AcfSignedOutToUpdateToken", 1, "Count"),
        });
        Auth.signOut();
      }
    } catch (err) {
      Logger.sessionError(`Error occured during User log out to force token update ${err}`, {
        emfLog: createEMFObject("AcfSignedOutToUpdateTokenFailure", 1, "Count"),
      });
    }
    Auth.currentAuthenticatedUser()
      .then(cognitoUser => {
        setToken(cognitoUser.signInUserSession.idToken.jwtToken);
        setUser(parseJwt(cognitoUser.signInUserSession.idToken.jwtToken));
        verifyUserRights(parseJwt(cognitoUser.signInUserSession.idToken.jwtToken));
        setUserInfo({
          user: parseJwt(cognitoUser.signInUserSession.idToken.jwtToken),
          token: cognitoUser.signInUserSession.idToken.jwtToken,
        });
        // localStorage.setItem("token", cognitoUser.signInUserSession.idToken.jwtToken);
        const redirectURL = localStorage?.getItem("redirectURL");
        if (location.search.slice(0, 6) === "?code=" && redirectURL) {
          localStorage.removeItem("redirectURL");
          window.location.replace(redirectURL, redirectURL);
          return null;
        }
      })
      .catch(error => {
        Logger.sessionError(`Authentication error -, ${error}`, {
          emfLog: createEMFObject("AuthenticationErrors", 1, "Count"),
        });
        localStorage.setItem("redirectURL", window.location.pathname);
        Auth.federatedSignIn({ provider: "Federate" });
      });
  };

  const verifyUserRights = userData => {
    const permissions = {};
    try {
      const userId = userData?.identities?.[0]?.userId || "";
      const ldapgrp = JSON.parse(userData["custom:ldapgrp"]);
      const isReviewer = ldapgrp.includes("ad-sales-tech-product-clf-reviewers") ? true : false;
      const isThemeAdmin = ldapgrp.includes("theme-admin") || userId === IT_TEST_USER ? true : false;
      const isAdmin = ldapgrp.includes("admin-view") ? true : false;
      permissions[IS_MAPPING_REVIEWER] = isReviewer;
      permissions[IS_THEME_ADMIN] = isThemeAdmin;
      permissions[IS_ADMIN] = isAdmin;
      setUserRights(permissions);
      setUserPermissions(permissions);
    } catch (err) {
      Logger.sessionError(`unable to parse user token: ${err}`, {
        emfLog: createEMFObject("UserTokenParseError", 1, "Count"),
      });
      setIsError(true);
    }
  };

  const loadStaticData = () => {
    console.log("load static from routes");
    setLoadingStaticData(true);
    const params = {};
    apiClient
      .get("/static?size=10000", { params })
      .then(response => {
        updateStaticData(
          response?.data?.results?.map(stat => {
            return Object.assign(stat.fields, { id: stat.id });
          })
        );
      })
      .catch(error => {
        Logger.sessionError(`fetching staticdata failed ${error}`, {
          browserUrl: window.location.href,
        });
        if (error && error.response && error.response.status >= 400) {
          setIsError(true);
        }
      })
      .then(() => {
        setLoadingStaticData(false);
      });
  };

  const fetchFromIndexedDb = async () => {
    for (const key in INDEXED_DB_STORE) {
      const iDbKey = INDEXED_DB_STORE[key];
      const data = await getFromIndexedDb(iDbKey);
      if (data) {
        if (key === "feedbacksList") {
          updateFeedbackList(data);
        } else if (key === "myFeedbacksList") {
          updateMyFeedbackList(data);
        } else if (key === "lastUpdatedTimeAllFeedback") {
          setlastUpdatedTimeAllFeedback(data);
        } else if (key === "lastUpdatedTimeMyFeedback") {
          setlastUpdatedTimeMyFeedback(data);
        }
        Logger.sessionInfo("store Initialised from IndexedDb", {
          emfLog: createEMFObject("StoreInitialisedfromIndexedDb", 1, "Count", {
            Cache: "IndexDbFlush",
          }),
        });
      } else {
        Logger.sessionInfo("no data in IndexedDb", {
          emfLog: createEMFObject("EmptyIndexedDb", 1, "Count", {
            Cache: "EmptyIndexedDb",
          }),
        });
      }
    }
  };

  const logUserActivity = user => {
    if (!sessionStorage.getItem("uuid")) {
      sessionStorage.setItem("uuid", uuidv4());
      Logger.sessionInfo("unique session created today", {
        emfLog: createEMFObject("ACFUniqueSessionCount", 1, "Count"),
      });
    } else {
      Logger.sessionInfo("session refreshes", {
        emfLog: createEMFObject("ACFSessionRefreshCount", 1, "Count"),
      });
    }
    let userLastActive = "";
    try {
      userLastActive = localStorage.getItem("userLastActive");
    } catch (err) {
      Logger.sessionError(`Error occured while retrieving user activity, ${err}`);
    }
    if (!userLastActive) {
      try {
        localStorage.setItem("userLastActive", `${Date.now()};${user.email}`);
        Logger.sessionInfo("unique user visited today", {
          emfLog: createEMFObject("ACFUniqueUserVisit", 1, "Count"),
        });
      } catch (e) {
        Logger.sessionError(`error occured while setting userLastActivity, ${e}`);
      }
    } else {
      console.log(userLastActive);
      const lastActiveTimeStamp = parseInt(userLastActive.split(";")[0]);
      const lastUserEmail = userLastActive.split(";")[1];
      if (lastUserEmail !== user.email) {
        Logger.sessionInfo(`Different user from same machine ${lastUserEmail} ${user.email}`, {
          emfLog: createEMFObject("ACFDifferentUserFromSameMachine", 1, "Count"),
        });
      }
      if (isOlderdate(lastActiveTimeStamp) || lastUserEmail !== user.email) {
        try {
          localStorage.setItem("userLastActive", `${Date.now()};${user.email}`);
          Logger.sessionInfo("unique user visited today", {
            emfLog: createEMFObject("ACFUniqueUserVisit", 1, "Count"),
          });
        } catch (e) {
          Logger.sessionError(`error occured while setting userLastActivity, ${e}`);
        }
      } else {
        // count user  duplicate visits in a day
        Logger.sessionInfo("duplicate visit", {
          emfLog: createEMFObject("ACFUserDuplicateVisit", 1, "Count"),
        });
      }
    }
  };
  useEffect(() => {
    const {
      location: { search },
    } = history;
    const search_params = search;
    const params = new URLSearchParams(search_params);
    const debugRoot = params.get("root");
    if (!inIframe() && !debugRoot && getEnvironment() === "prod") {
      window.location.replace("https://vector.advertising.amazon.dev/customer-feedback");
    }
    if (user) {
      logUserActivity(user);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    performance.mark(PerformanceMarker.NewPageload);
    async function validateIDb() {
      if (
        location?.pathname === "/" ||
        location?.pathname === "/feature" ||
        location.pathname === "/feedback" ||
        location.pathname === "/trends"
      ) {
        const myFeedbackPage = location?.pathname === "/feedback";
        await initializeLocalStoreFromIndexDb(myFeedbackPage);
      } else {
        setLoadingStore(false);
        setIsIdbInitialised(true);
      }
    }
    if (user) {
      validateIDb();
    }
    // eslint-disable-next-line
  }, [location, user]);

  useEffect(() => {
    authenticate();
    loadStaticData();
    // if(location?.pathname==="/" || location?.pathname === "/feature" || location.pathname === "feedback") {
    //     const myFeedbackPage = location?.pathname === '/feedback'
    //     initializeLocalStoreFromIndexDb(myFeedbackPage);
    // }
    // console.log(location)

    const {
      location: { search },
    } = history;
    const search_params = search;
    const params = new URLSearchParams(search_params);
    const srcParam = params.get("src");
    if (srcParam) {
      setSrc(srcParam);
    }
    performance.mark(PerformanceMarker.AppConstructor);
    // eslint-disable-next-line
  }, []);

  const purgeIdb = async () => {
    updateFeedbackList([]);
    updateMyFeedbackList([]);
    setlastUpdatedTimeAllFeedback("");
    setlastUpdatedTimeMyFeedback("");
    await deleteManyfromIndexedDb(Object.values(INDEXED_DB_STORE));
  };

  const initializeLocalStoreFromIndexDb = async myFeedbackPage => {
    setLoadingStore(true);
    try {
      let twoYearFilter = `lastUpdateTimestamp:${getTwoyearOldTimeStamp()}`;
      if (myFeedbackPage) {
        twoYearFilter = twoYearFilter + `+submittedByEmail:${user.email}`;
      }
      const twoYearsdata = await getFeedbacks(twoYearFilter, 0, 1);
      const {
        data: { totalItemsCount: twoYearDataCount },
      } = twoYearsdata;
      setTwoYearLength(twoYearDataCount);

      if (!isFeatureFlagVerified) {
        const featureFlags = await getFeatureFlags();
        setFeatureFlags(featureFlags?.data?.featureFlags?.paginationFlag?.enabled);
        if (featureFlags?.data?.featureFlags?.featureIndexedDb?.enabled === false) {
          await purgeIdb();
          setLoadingStore(false);
          setIsIdbInitialised(true);
          return;
        }
        setIsFeatureFlagVerified(true);
      }

      //check if purge flag is enabled
      const userId = user.email.split("@")[0];
      if (!isPurgeflagVerified) {
        if (userId) {
          const purgeStatus = await getPurgeStatus(userId);
          console.log(purgeStatus.data.purgeInfo);
          if (purgeStatus?.data?.purgeInfo?.fields?.flag === "true") {
            await purgeIdb();
            await deletePurgeRecord(userId);
          }
          setIsPurgeFlagVerified(true);
        }
      }
      let deltaTime;
      let feedbackData;
      if (myFeedbackPage) {
        const dbData = await getManyFromIndexedDb([
          INDEXED_DB_STORE.myFeedbacksList,
          INDEXED_DB_STORE.lastUpdatedTimeMyFeedback,
        ]);
        [feedbackData, deltaTime] = dbData;
      } else {
        const dbData = await getManyFromIndexedDb([
          INDEXED_DB_STORE.feedbacksList,
          INDEXED_DB_STORE.lastUpdatedTimeAllFeedback,
        ]);
        [feedbackData, deltaTime] = dbData;
      }

      if (feedbackData && deltaTime) {
        let filter = `lastUpdateTimestamp:${deltaTime}`;
        if (myFeedbackPage) {
          filter = filter + `+submittedByEmail:${user.email}`;
        }
        Logger.sessionInfo(`Applied filter for getFeedbacks : ${filter}`);
        const response = await getFeedbacks(filter, 0, 1);

        const {
          data: { totalItemsCount: deltaCount },
        } = response;
        const cacheLength = feedbackData.length;
        if (deltaCount + cacheLength - 1 >= twoYearDataCount) {
          await fetchFromIndexedDb();
          Logger.sessionInfo(
            `IndexedDb valid: twoYearCount: ${twoYearDataCount}, cacheLength: ${cacheLength}, deltaCount: ${deltaCount}`,
            {
              emfLog: createEMFObject("IndexDbValid", 1, "Count", {
                Cache: "IndexDbValid",
              }),
            }
          );
          Logger.sessionInfo(
            `IndexedDb valid: twoYearCount: ${twoYearDataCount}, cacheLength: ${cacheLength}, deltaCount: ${deltaCount}`
          );
        } else {
          //clearIndexDb
          await purgeIdb();
          Logger.sessionInfo(
            `data mismatch caused IndexedDb flush: twoYearCount: ${twoYearDataCount}, cacheLength: ${cacheLength}, deltaCount: ${deltaCount}`,
            {
              emfLog: createEMFObject("IndexDbFlush", 1, "Count", {
                Cache: "IndexDbFlush",
              }),
            }
          );
        }
      }
      //set flag
      setIsIdbInitialised(true);
      Logger.sessionInfo(`IndexedDb validated`, {
        emfLog: createEMFObject("IndexDbValidationSuccess", 1, "Count", {
          Cache: "IndexDbValidationSuccess",
        }),
      });
    } catch (err) {
      Logger.sessionError(`Error occured while validating indexedDb`, {
        emfLog: createEMFObject("IndexDbValidationError", 1, "Count", {
          Cache: "IndexDbValidationError",
        }),
      });
      await purgeIdb();
      setIsError(true);
    }
    setLoadingStore(false);
  };

  if (loadingStore || loadingStaticData) {
    return (
      <Row alignmentHorizontal="center" spacingInset="400">
        <Loader />
      </Row>
    );
  }

  if (isError) {
    return <div className="page">{isError && <ErrorAlert componentName="Routes" />}</div>;
  }
  if (!token) {
    return (
      <div className="page">
        <Loader />
      </div>
    );
  }

  const checkProtectedRouteAccess = route => {
    if (route.isAdminRoute) {
      if (!userRights[IS_ADMIN]) {
        return false;
      }
    }
    if (route.isThemeAdmin) {
      if (!userRights[IS_THEME_ADMIN]) {
        return false;
      }
    }
    if (route.isAdminOrReviewer) {
      if (!userRights[IS_ADMIN] && !userRights[IS_MAPPING_REVIEWER]) {
        return false;
      }
    }
    if (route.isReviewerRoute) {
      if (!userRights[IS_MAPPING_REVIEWER]) {
        return false;
      }
    }
    return true;
  };

  if (isIdbInitialised && user && token) {
    return (
      <UserContext.Provider value={{ token, user, src }}>
        <Switch>
          {routeMapping.map((route, index) => {
            if (!checkProtectedRouteAccess(route)) {
              return null;
            }
            return <Route key={index} path={route.path} exact component={route.component} />;
          })}
          <Redirect from="/*" to="/" />
        </Switch>
      </UserContext.Provider>
    );
  }

  return (
    <>
      {loadingStore || loadingStaticData ? (
        <Row alignmentHorizontal="center" spacingInset="400">
          <Loader />
        </Row>
      ) : isError ? (
        <div className="page">{isError && <ErrorAlert componentName="Routes" />}</div>
      ) : !token ? (
        <div className="page">
          <Loader />
        </div>
      ) : (
        isIdbInitialised &&
        user &&
        token && (
          <UserContext.Provider value={{ token, user, src }}>
            <Switch>
              {routeMapping.map((route, index) => {
                if (!checkProtectedRouteAccess(route)) {
                  return null;
                }
                return <Route key={index} path={route.path} exact component={route.component} />;
              })}
              <Redirect from="/*" to="/" />
            </Switch>
          </UserContext.Provider>
        )
      )}
    </>
  );
};

export default Routes;
