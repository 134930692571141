import { UnifiedDataTable } from "@amzn/unified-data-table-components/pack";
import React from "react";

export class ServerSideTable extends React.Component {
  getEnhancedGridOptions() {
    const { gridOptions } = this.props;

    return {
      ...gridOptions,
      // Add server-side configurations
      pagination: true,
      rowModelType: "serverSide",
      serverSideStoreType: "partial",
      cacheBlockSize: 300,
      maxBlocksInCache: 1,

      // Row count maintenance
      maintainRowCount: true,
      suppressRowCountChangedEvent: false,

      // Default column definitions
      defaultColDef: {
        ...gridOptions.defaultColDef,
        headerClass: "tableHeader",
        cellClass: "tableCell",
        suppressMenu: true,
      },

      // Server-side specific options
      masterDetail: false,
      rowBuffer: gridOptions.paginationPageSize,
      suppressPaginationPanel: false,
      paginationPageSize: 300, // Default page size

      // Enhanced grid ready handler
      onGridReady: params => {
        if (gridOptions.onGridReady) {
          gridOptions.onGridReady(params);
        }
        // Set initial row count if available
        if (params.api && gridOptions.rowCount) {
          params.api.paginationSetTotalRows(gridOptions.rowCount);
        }
      },

      // Enhanced pagination changed handler
      onPaginationChanged: params => {
        // Refresh the grid data
        if (gridOptions.onPaginationChanged) {
          gridOptions.onPaginationChanged(params);
        }
        // Update row count when pagination changes
        if (params.api && gridOptions.rowCount) {
          params.api.paginationSetTotalRows(gridOptions.rowCount);
        }
      },

      // Add handler for row data updates
      onRowDataUpdated: params => {
        if (params.api && gridOptions.rowCount) {
          params.api.paginationSetTotalRows(gridOptions.rowCount);
        }
        if (gridOptions.onRowDataUpdated) {
          gridOptions.onRowDataUpdated(params);
        }
      },

      // Add handler for server-side store updates
      onServerSideStoreUpdated: params => {
        if (gridOptions.onServerSideStoreUpdated) {
          gridOptions.onServerSideStoreUpdated(params);
        }
      },
    };
  }

  render() {
    const {
      uidPrefix,
      tableFooter,
      onGridReady,
      headerPanel,
      onFilterChanged,
      onColumnPresetChanged,
      frameworkComponents,
    } = this.props;

    return (
      <UnifiedDataTable
        frameworkComponents={frameworkComponents || {}}
        gridOptions={this.getEnhancedGridOptions()}
        uidPrefix={uidPrefix}
        onGridReady={onGridReady}
        footerPanel={tableFooter}
        headerPanel={headerPanel}
        onFilterChanged={onFilterChanged}
        onColumnPresetChanged={onColumnPresetChanged}
        maxTableHeight="67vh"
        locale="en-US"
      />
    );
  }
}
