import { FilterCriteria } from "../types/FilterCriteria";
import { BaseProcessor } from "./BaseProcessor";
import { FILTER_TYPES, FILTER_OPERATIONS } from "../constants/FilterConstants";

export class TextProcessor extends BaseProcessor {
  canProcess(filterType: string): boolean {
    return filterType === FILTER_TYPES.TEXT;
  }

  process(field: string, criteria: FilterCriteria): string {
    if (criteria.type === FILTER_OPERATIONS.TEXT.CONTAINS) {
      return this.createFilterString(field, `*${criteria.filter}*`);
    }
    return this.createFilterString(field, criteria.filter?.toString() || "");
  }
}
