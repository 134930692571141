export const FILTER_TYPES = {
  TEXT: "text",
  NUMBER: "number",
  DATE: "dateRange",
  MULTI_SELECT: "multiSelectWithSearch",
} as const;

export const FILTER_OPERATIONS = {
  TEXT: {
    CONTAINS: "contains",
  },
  NUMBER: {
    LESS_THAN: "lessThan",
    LESS_THAN_OR_EQUAL: "lessThanOrEqual",
    NOT_EQUAL: "notEqual",
    GREATER_THAN: "greaterThan",
    GREATER_THAN_OR_EQUAL: "greaterThanOrEqual",
  },
} as const;
