import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Divider, Button, TextButton, TextAreaFormGroup, Alert } from "@amzn/storm-ui";
import { Dropdown, DropdownItem } from "@amzn/storm-ui";
import { Form, FormRow } from "@amzn/storm-ui-column-form-kit";
import { useStore } from "../../store";
import Logger from "../../util/logger";
import Row from "../../components/row";
import { ThemeProvider } from "styled-components";
import { overrideZIndex, createEMFObject, stringComparatorForArrayOfObjects } from "../../util/util";
import { THEME_PRIORITY, IS_MAPPING_REVIEWER } from "../../util/constants";
import { createTheme, updateTheme } from "../../util/apiUtils";
import Loader from "../../components/Loader";
import _ from "lodash";
import ErrorAlert from "../../components/error-alert/ErrorAlert";

const CreateFeedbackThemesform = props => {
  const [themeNameInput, setThemeNameInput] = useState("");
  const [description, setDescription] = useState("");
  const [themeStatus, setThemeStatus] = useState("New");
  const [isError, setIsError] = useState(false);
  const [selectedProductService, setSelectedProductService] = useState("");
  const [productServices, setProductServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [themePriority, setThemePriority] = useState("low");
  const [themeEta, setThemeEta] = useState("");
  const [formEdited, setFormEdited] = useState(false);
  const [duplicatethemeFound, setDuplicatethemeFound] = useState(false);
  const [overRideReason, setOverrideReason] = useState("");
  const history = useHistory();
  const { mode, source, closeEditModal, selectedRowItems, isThemeEdited, duplicateThemeName } = props;
  const { userPermissions } = useStore();
  const staticData = useStore(state => state.staticData);

  // const userInfo = useStore((state) => state.userInfo);
  // const { staticEndpoint } = endpoints;

  useEffect(() => {
    const { changeApplyButtonDisabledProperty } = props;
    let servicessList = staticData.filter(data => data.docType === "PRODUCT_SERVICES" && data.value !== "All Products");
    servicessList = _.uniqBy(servicessList, "value");
    servicessList.sort(stringComparatorForArrayOfObjects);
    setProductServices(servicessList);
    if (mode === "Edit" && changeApplyButtonDisabledProperty) {
      changeApplyButtonDisabledProperty(true);
      prepopulateFields();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mode === "Edit" && themeNameInput && themeStatus && selectedProductService && description) {
      const { editedPayload, selectedRowItems } = props;
      const {
        rowData: {
          productServices: existingProdService,
          themeName,
          themeStatus: existingStatus,
          additionalContext,
          themePriority: existingThemePriority,
          themeEta: existingThemeEta,
        },
      } = selectedRowItems[0];
      console.log(existingThemePriority);
      editedPayload({
        themeNameInput,
        themeStatus,
        selectedProductService,
        additionalContext: description,
        themePriority,
      });
      if (
        themeNameInput !== themeName ||
        themeStatus !== existingStatus ||
        selectedProductService !== existingProdService ||
        description !== additionalContext ||
        themePriority !== existingThemePriority ||
        themeEta !== existingThemeEta
      ) {
        setFormEdited(true);
      } else {
        setFormEdited(false);
      }
    }
    // eslint-disable-next-line
  }, [themeNameInput, themeStatus, selectedProductService, description, themePriority, themeEta]);

  useEffect(() => {
    const { changeApplyButtonDisabledProperty } = props;
    if (mode === "Edit" && changeApplyButtonDisabledProperty) {
      if (formEdited) {
        changeApplyButtonDisabledProperty(false);
      } else {
        changeApplyButtonDisabledProperty(true);
      }
    }
    // eslint-disable-next-line
  }, [formEdited]);

  const prepopulateFields = () => {
    const { selectedRowItems } = props;
    const {
      rowData: { productServices, themeName, themeStatus, additionalContext, themePriority, themeEta },
    } = selectedRowItems[0];
    setSelectedProductService(productServices);
    setThemeNameInput(themeName);
    setThemeStatus(themeStatus);
    setDescription(additionalContext);
    if (themePriority) {
      setThemePriority(themePriority);
    }
    if (themeEta) {
      setThemeEta(themeEta);
    }
  };

  const closeCreateModal = () => {
    history.push("/themes");
  };

  const createThemeSuccess = res => {
    Logger.sessionInfo(`${mode} Feedback Theme successfull`, {
      emfLog: createEMFObject(`${mode}FeedbackThemeCounts`, 1, "Count"),
    });
    history.push("/themes");
  };

  const closeEditedModal = () => {
    closeEditModal(false);
  };
  const OnSubmit = () => {
    handleUpdateTheme();
  };

  const updateThemeSuccess = () => {
    closeEditModal(false);
    isThemeEdited(true);
  };

  const handleUpdateTheme = async () => {
    const {
      rowData: { id, themeName, additionalContext, productServices, updateSource, source },
    } = selectedRowItems[0];
    setIsLoading(true);
    const isNameChanges = (themeName !== themeNameInput).toString();
    const hasDescriptionChanged = (additionalContext !== description).toString();
    const hasProductServiceChanged = (selectedProductService !== productServices).toString();
    const updateThemeObj = {
      themeId: id,
      input: {
        fields: {
          ...selectedRowItems[0].rowData,
          themeStatus,
          productServices: selectedProductService,
          themeName: themeNameInput,
          additionalContext: description,
          themePriority,
          themeEta,
          isNameChanges,
          hasDescriptionChanged,
          overRideReason,
          hasProductServiceChanged,
        },
      },
    };
    try {
      const createdTheme = await updateTheme(updateThemeObj, id);
      if (
        !userPermissions[IS_MAPPING_REVIEWER] &&
        (updateSource === "dataScience" || source === "dataScience") &&
        (isNameChanges || hasDescriptionChanged || hasProductServiceChanged)
      ) {
        if (sessionStorage !== null) {
          sessionStorage.setItem("isMappingApprovalCaseCreated", true);
        }
      }
      if (
        createdTheme?.data?.themeSummary ===
        "Theme with same name is already exist so can not process the update request"
      ) {
        setDuplicatethemeFound(true);
      } else {
        Logger.sessionInfo(`theme got updated ${id}`, {
          emfLog: createEMFObject("UpdateTheme", 1, "Count"),
        });
        updateThemeSuccess(createdTheme);
      }
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
      Logger.sessionError(`Theme Update error - ${err}`, {
        emfLog: createEMFObject("ThemeUpdateError", 1, "Count"),
      });
    }
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let submitObj = {};
    submitObj = {
      fields: {
        themeStatus,
        productServices: selectedProductService,
        themeName: themeNameInput,
        additionalContext: description,
        blockedRevenue: "0",
        feedbackCount: "0",
        themePriority,
      },
    };
    try {
      const createdTheme = await createTheme(submitObj);
      if (createdTheme?.data?.themeSummary === "Theme with same name already exists") {
        setDuplicatethemeFound(true);
      } else {
        createThemeSuccess(createdTheme);
      }
    } catch (err) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const validateSubmit = () => {
    return selectedProductService && themeNameInput && description && themeStatus && themePriority;
  };

  if (isLoading || isError) {
    return (
      <div className="container-container">
        {isLoading && <Loader />}
        {isError && <ErrorAlert componentName="CreateFeedbackThemesform" />}
      </div>
    );
  }

  return (
    <ThemeProvider theme={overrideZIndex}>
      <Form id="createLabel">
        <FormRow
          label="Product/Service"
          isSectionHeader
          id="feedback-type-row"
          controlComponents={[
            componentId => (
              <Dropdown
                onChange={val => setSelectedProductService(val)}
                selectedValue={selectedProductService}
                onOverrideLabel={(dropdownItem, value) => {
                  if (value) {
                    return dropdownItem;
                  }
                  return "Choose Product/Service to update";
                }}
                error={!selectedProductService}
              >
                {productServices.map((type, key) => {
                  return (
                    <DropdownItem key={key} value={type.value}>
                      {type.value}
                    </DropdownItem>
                  );
                })}
              </Dropdown>
            ),
          ]}
        />
        <FormRow
          label="Theme name"
          isSectionHeader
          id="feedback-type-row"
          controlComponents={[
            componentId => (
              <TextAreaFormGroup
                placeholder="Create feedback theme"
                value={themeNameInput}
                onChange={event => {
                  setThemeNameInput(event.target.value);
                }}
                statusType={
                  (selectedProductService && !themeNameInput) || duplicatethemeFound || duplicateThemeName
                    ? "error"
                    : undefined
                }
                message={duplicatethemeFound ? "A theme with same name already exists" : ""}
              />
            ),
          ]}
        />
        <Divider />
        <FormRow
          label="Description"
          isSectionHeader
          id="descriptionRow-row"
          controlComponents={[
            componentId => (
              <TextAreaFormGroup
                placeholder="Description"
                value={description}
                onChange={event => {
                  setDescription(event.target.value);
                }}
                statusType={themeNameInput && !description ? "error" : undefined}
              />
            ),
          ]}
        />
        {mode !== "Edit" && (
          <FormRow
            label="Priority"
            isSectionHeader
            id="feedback-type-row"
            controlComponents={[
              componentId => (
                <Dropdown
                  onChange={val => setThemePriority(val)}
                  selectedValue={themePriority}
                  onOverrideLabel={(dropdownItem, value) => {
                    if (dropdownItem) {
                      return dropdownItem;
                    }
                    return "low";
                  }}
                >
                  {THEME_PRIORITY.map((themePriority, key) => {
                    return (
                      <DropdownItem key={key} value={themePriority}>
                        {_.capitalize(themePriority)}
                      </DropdownItem>
                    );
                  })}
                </Dropdown>
              ),
            ]}
          />
        )}
        {mode === "Edit" && (
          <FormRow
            label="Override reason"
            isSectionHeader
            id="descriptionRow-row"
            controlComponents={[
              componentId => (
                <TextAreaFormGroup
                  placeholder="Tell us why you want make an edit"
                  value={overRideReason}
                  onChange={event => {
                    setOverrideReason(event.target.value);
                  }}
                  statusType={formEdited && !overRideReason ? "error" : undefined}
                />
              ),
            ]}
          />
        )}
        {mode === "Edit" && (
          <FormRow
            // isSectionHeader
            id="descriptionRow-row"
            controlComponents={[
              componentId => (
                <Alert inline={true} type="warning">
                  Any request to edit SalesAI theme's name and description will be reviewed before they are approved.{" "}
                </Alert>
              ),
            ]}
          />
        )}
        {/* <FormRow
                    label="Status"
                    isSectionHeader
                    id="feedback-type-row"
                    optionalTag="Required"
                    controlComponents={[
                        (componentId) => (
                            <Dropdown
                                disabled={mode === "Create"}
                                onChange={(val) => setThemeStatus(val)}
                                selectedValue={themeStatus}
                                onOverrideLabel={(dropdownItem, value) => {
                                    if (mode === "Create") {
                                        return "New";
                                    } else if (value) {
                                        return dropdownItem;
                                    }
                                    return 'Choose status to update';
                                }}
                            >
                                {THEME_STATUS.map((themeStatus, key) => {
                                    return <DropdownItem key={key} value={themeStatus} >{themeStatus}</DropdownItem>
                                })}
                            </Dropdown>
                        ),
                    ]}
                /> */}
        {mode === "Create" && (
          <>
            <Divider />
            <Row alignmentHorizontal="right">
              <TextButton onClick={closeCreateModal}>Cancel</TextButton>
              <Button disabled={!validateSubmit()} primary={true} onClick={handleSubmit}>
                {`${mode} theme`}
              </Button>
            </Row>
          </>
        )}
        {mode === "Edit" && source === "themesDetailsPage" && (
          <>
            <Divider />
            <Row alignmentHorizontal="right">
              <TextButton onClick={closeEditedModal}>Cancel</TextButton>
              <Button disabled={!formEdited || !overRideReason} primary={true} onClick={OnSubmit}>
                {`${mode} Theme`}
              </Button>
            </Row>
          </>
        )}
      </Form>
    </ThemeProvider>
  );
};

export default CreateFeedbackThemesform;
