import { FilterCriteria } from "../types/FilterCriteria";
import { BaseProcessor } from "./BaseProcessor";
import { FILTER_TYPES, FILTER_OPERATIONS } from "../constants/FilterConstants";

export class NumberProcessor extends BaseProcessor {
  canProcess(filterType: string): boolean {
    return filterType === FILTER_TYPES.NUMBER;
  }

  process(field: string, criteria: FilterCriteria): string {
    switch (criteria.type) {
      case FILTER_OPERATIONS.NUMBER.LESS_THAN:
        return this.createFilterString(field, `{* TO ${criteria.filter}}`);
      case FILTER_OPERATIONS.NUMBER.LESS_THAN_OR_EQUAL:
        return this.createFilterString(field, `[* TO ${criteria.filter}]`);
      case FILTER_OPERATIONS.NUMBER.NOT_EQUAL:
        return `-${field}:${criteria.filter}`;
      case FILTER_OPERATIONS.NUMBER.GREATER_THAN:
        return this.createFilterString(field, `{${criteria.filter} TO *}`);
      case FILTER_OPERATIONS.NUMBER.GREATER_THAN_OR_EQUAL:
        return this.createFilterString(field, `[${criteria.filter} TO *]`);
      default:
        return this.createFilterString(field, criteria.filter?.toString() || "");
    }
  }
}
