import React, { useEffect, useState } from "react";
import { DropdownItem, Alert, TextAreaFormGroup } from "@amzn/storm-ui";
import { getThemes } from "../../util/apiUtils";
import { ThemeProvider } from "styled-components";
import { overrideZIndex, dedupResponse } from "../../util/util";
import Loader from "../../components/Loader";
import Column from "../../components/column";
import FilterDropdown from "@amzn/storm-ui-filter-dropdown";
import Logger from "../../util/logger";
import { TABLE_ROWS_COUNT } from "../../util/constants";
import ErrorAlert from "../../components/error-alert/ErrorAlert";

const AttachToThemeDialog = props => {
  const [selectedTheme, setSelectedTheme] = useState();
  const [themes, setThemes] = useState([]);
  const [themeFilteredOptions, setThemeFilteredOptions] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [feedbackWithThemeList, setFeedbackWithThemeList] = useState([]);
  const [mappingReason, setMappingReason] = useState("");

  const { setSelectedThemeDetails, selectedRowItems, changeApplyButtonDisabledProperty } = props;

  const filterDeletedThemes = themeList => {
    return themeList.filter(theme => !theme.fields.isDeleted || theme.fields.isDeleted !== "1");
  };

  const fetchThemes = async () => {
    try {
      let initialData = [];
      const initialThemeResponse = await getThemes(0, TABLE_ROWS_COUNT);
      initialData = filterDeletedThemes(initialThemeResponse.data.results);
      let requestsMade = TABLE_ROWS_COUNT;
      let bucketSize = TABLE_ROWS_COUNT;
      let index = 1;
      const ceilingBucketSize = 2000;
      while (requestsMade < initialThemeResponse.data.totalItemsCount) {
        index++;
        if (bucketSize < ceilingBucketSize) {
          bucketSize = TABLE_ROWS_COUNT * index;
        }
        const themeResponse = await getThemes(requestsMade, bucketSize);
        const {
          data: { results: moreTableData },
        } = themeResponse;
        const filteredMoreData = filterDeletedThemes(moreTableData);
        initialData = [...initialData, ...filteredMoreData];
        console.log(initialData);
        requestsMade += bucketSize;
      }
      initialData = dedupResponse(initialData);
      return initialData;
    } catch (error) {
      Logger.sessionError(
        `AttachThemeModal componentDidMount useEffect
                 ${error}`,
        {
          browserUrl: window.location.href,
        }
      );
      setIsError(true);
    }
  };

  useEffect(() => {
    if (selectedTheme && mappingReason !== "") {
      changeApplyButtonDisabledProperty(false);
    } else {
      changeApplyButtonDisabledProperty(true);
    }
    // eslint-disable-next-line
  }, [selectedTheme, mappingReason]);

  useEffect(() => {
    changeApplyButtonDisabledProperty(true);
    console.log(props);
    const feedbackWithExistingThemeList = selectedRowItems.filter(rowItem => rowItem.rowData.parentTheme);
    if (feedbackWithExistingThemeList.length > 0) {
      setFeedbackWithThemeList(feedbackWithExistingThemeList);
    }

    async function fetchData() {
      try {
        setIsLoading(true);
        const themeList = await fetchThemes();
        console.log(themeList);
        // themeList.sort((a, b) => (a.fields.themeName > b.fields.themeName) ? 1 : -1);
        setThemes(themeList);
        setThemeFilteredOptions(themeList);
        setIsLoading(false);
      } catch (err) {
        Logger.sessionError(`error occured while fetching themeList ${err}`);
        setIsLoading(false);
        setIsError(true);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || isError) {
    return (
      <div className="container-container-small">
        {isLoading && <Loader />}
        {isError && <ErrorAlert componentName="AttachToThemeDialog" />}
      </div>
    );
  }

  const mappingforFilterDropDown = items => items.map(item => item.item);
  const onThemeFilterChange = items => {
    items = mappingforFilterDropDown(items);
    if (items.length === themes.length) {
      setThemeFilteredOptions(themes);
    } else {
      setThemeFilteredOptions(items);
    }
  };

  // const checkDisabledPropertyForSubmitButton = () => {
  //     if(selectedTheme && mappingReason !== ""){
  //         changeApplyButtonDisabledProperty(false);
  //     } else {
  //         changeApplyButtonDisabledProperty(true);
  //     }
  // }

  const options = {
    keys: ["fields.themeName"],
    threshold: 0.4,
  };

  return (
    <ThemeProvider theme={overrideZIndex}>
      <Column>
        {feedbackWithThemeList.length > 0 && (
          <Alert type="warning">
            Some feedbacks are already mapped under a different theme. Continuing would clear the existing mapping of
            the associated feedbacks.{" "}
          </Alert>
        )}
        {
          <Alert type="warning">
            Your request to map the feedbacks to the selected themes will be reviewed before they are approved.{" "}
          </Alert>
        }
        <FilterDropdown
          error={!selectedTheme}
          placeholder="Search Theme"
          onFilterChange={onThemeFilterChange}
          onFilterClear={() => setThemeFilteredOptions(themes)}
          options={options}
          fullWidth
          onChange={value => {
            setSelectedTheme(value);
            setSelectedThemeDetails(value, "themeInfo");
            // checkDisabledPropertyForSubmitButton();
          }}
          onOverrideLabel={(dropdownItem, value) => {
            if (value) {
              return dropdownItem;
            }
            return "Choose theme";
          }}
          selectedValue={selectedTheme}
          items={themes}
        >
          {themeFilteredOptions.map(themeItem => (
            <DropdownItem key={themeItem.id} value={themeItem}>
              {themeItem?.fields?.themeName}
            </DropdownItem>
          ))}
        </FilterDropdown>
        <TextAreaFormGroup
          id="mappingOverrideReason"
          label="Override reason"
          fullWidth
          value={mappingReason}
          onChange={event => {
            setMappingReason(event?.target?.value);
            setSelectedThemeDetails(event?.target?.value, "mappingReason");
            // checkDisabledPropertyForSubmitButton();
          }}
        />
      </Column>
    </ThemeProvider>
  );
};

export default AttachToThemeDialog;
