import Papa from "papaparse";
import { getTwoyearOldTimeStamp, processFeedbacks } from "./util";
import { getFeedbacks } from "../util/apiUtils";
import Logger from "./logger";
import { FeedbackData } from "../util/FeedbackInterface";

interface ExportParams {
  totalItemCount: number;
  onProgressUpdate: (progress: number) => void;
  filters: string;
}

interface FeedbackResponse {
  data?: {
    results: FeedbackData[];
  };
}

const requiredColumns: (keyof FeedbackData)[] = [
  "id",
  "submittedByEmail",
  "csatScore",
  "accountId",
  "accountName",
  "agencyAssociatedAccountId",
  "agencyAssociatedAccountName",
  "category",
  "subCategory",
  "customerVerbatim",
  "translatedCustomerVerbatim",
  "advertisingSubProduct",
  "revenueImpacted",
  "createdTimestamp",
  "accountMarket",
  "submittedByName",
  "managerAlias",
  "accountRecordType",
  "accountVertical",
  "accountChannel",
  "salesTeam",
  "additionalContext",
  "translatedAdditionalContext",
  "whyThisImportant",
  "translatedWhyThisImportant",
  "csatScore",
  "accountOwnerName",
  "feedbackLabels",
  "attachments",
  "parentThemeName",
  "themeStatus",
  "updateSource",
];

/**
 * Converts epoch timestamp to date string in format YYYY-MM-DD HH:mm:ss
 * @param epoch - Epoch timestamp in milliseconds
 * @returns Formatted date string
 */
const formatTimestamp = (epoch: string): string => {
  const epochNumber = Number(epoch);
  const milliseconds = epoch.length === 10 ? epochNumber * 1000 : epochNumber;
  const date = new Date(milliseconds);
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

/**
 * Filters an array of objects to keep only the specified columns and formats timestamps
 * @param data - Array of objects to filter
 * @returns Filtered array containing only the required columns with formatted dates
 */
const filterColumns = (data: FeedbackData[]): Partial<FeedbackData>[] => {
  return data.map(row => {
    const filteredRow: Partial<FeedbackData> = {};
    Object.keys(row).forEach(key => {
      if (requiredColumns.includes(key as keyof FeedbackData)) {
        if (key === "createdTimestamp") {
          filteredRow[key] = formatTimestamp(row[key] as string);
        } else {
          filteredRow[key as keyof FeedbackData] = row[key];
        }
      }
    });
    return filteredRow;
  });
};

export const convertToCSV = (data: FeedbackData[]): string => {
  const filteredData = filterColumns(data);
  return Papa.unparse(filteredData, {
    quotes: true,
    skipEmptyLines: true,
  });
};

export const downloadCSV = (csvData: string, filename: string): void => {
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

async function getFeedbackList(
  from: number,
  size: number,
  fromTimeStamp: number,
  filters: string
): Promise<FeedbackResponse> {
  try {
    if (!fromTimeStamp) {
      fromTimeStamp = getTwoyearOldTimeStamp();
    }
    filters += `+lastUpdateTimestamp:${fromTimeStamp}`;

    return getFeedbacks(filters, from, size);
  } catch (error) {
    if (error instanceof Error) {
      Logger.error("getFeedbackList failed", {
        error: error.message,
        from,
        size,
        fromTimeStamp,
      });
    }
    throw error;
  }
}

export const handleServerSideExport = async ({
  totalItemCount,
  onProgressUpdate,
  filters,
}: ExportParams): Promise<void> => {
  const CEILING_BUCKET_SIZE = 1500;
  let requestedItemsCount = 0;
  let bucketSize = CEILING_BUCKET_SIZE;

  const updateProgress = (current: number): void => {
    onProgressUpdate(Math.min(current, 100));
  };

  const promises: Promise<FeedbackResponse>[] = [];
  while (requestedItemsCount < totalItemCount) {
    try {
      let deltaTime = getTwoyearOldTimeStamp();
      promises.push(getFeedbackList(requestedItemsCount, bucketSize, deltaTime, filters));
      requestedItemsCount += bucketSize;
    } catch (error) {
      throw error;
    }
  }

  let completed = 0;
  let results: FeedbackData[] = [];
  const totalPromises = promises.length;

  await Promise.all(
    promises.map(promise =>
      promise.then(response => {
        completed++;
        const progress = Math.min((completed / totalPromises) * 100, 100);
        onProgressUpdate(progress);
        results.push(...processFeedbacks(response?.data?.results));
        return results;
      })
    )
  );

  try {
    const csvData = convertToCSV(results);
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const timestamp = `${year}-${month}-${day}`;
    downloadCSV(csvData, `exportFeedbacks_${timestamp}.csv`);
    updateProgress(100);
  } catch (error) {
    throw error;
  }
};
