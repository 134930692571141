import { FilterCriteria } from "../types/FilterCriteria";
import { BaseProcessor } from "./BaseProcessor";
import { FILTER_TYPES } from "../constants/FilterConstants";

export class MultiSelectProcessor extends BaseProcessor {
  canProcess(filterType: string): boolean {
    return filterType === FILTER_TYPES.MULTI_SELECT;
  }

  process(field: string, criteria: FilterCriteria): string {
    if (!criteria.values?.length) return "";
    const values = criteria.values.map(item => item.value).join(" OR ");
    return this.createFilterString(field, values);
  }
}
